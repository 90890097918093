@tailwind base;
@tailwind components;
@tailwind utilities;

/* .Stage_wrapper__1X5K_ {
  background-color: white;
}

.Node_wrapper__3SmT7 {
  background-color: blue;
} */

[data-flume-component="node"] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity)) !important;
  --tw-text-opacity: 1 !important;
  color: rgb(209 213 219 / var(--tw-text-opacity)) !important;
}

[data-flume-component="node-header"] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity)) !important;
  font-weight: 700 !important;
  --tw-text-opacity: 1 !important;
  color: rgb(209 213 219 / var(--tw-text-opacity)) !important;
  font-style: italic !important;
}

[data-flume-component="stage"] {
  background-color: #ffffff !important;
}

[data-flume-component="port-label"] {
  --tw-text-opacity: 1 !important;
  color: rgb(209 213 219 / var(--tw-text-opacity)) !important;
  font-style: italic !important;
}